exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-da-dk-js": () => import("./../../../src/pages/about.da-dk.js" /* webpackChunkName: "component---src-pages-about-da-dk-js" */),
  "component---src-pages-blog-da-dk-js": () => import("./../../../src/pages/blog.da-dk.js" /* webpackChunkName: "component---src-pages-blog-da-dk-js" */),
  "component---src-pages-case-dbu-da-dk-js": () => import("./../../../src/pages/case-dbu.da-dk.js" /* webpackChunkName: "component---src-pages-case-dbu-da-dk-js" */),
  "component---src-pages-case-goedstrup-da-dk-js": () => import("./../../../src/pages/case-goedstrup.da-dk.js" /* webpackChunkName: "component---src-pages-case-goedstrup-da-dk-js" */),
  "component---src-pages-case-hormon-og-knoglesygdomme-auh-da-dk-js": () => import("./../../../src/pages/case-hormon-og-knoglesygdomme-auh.da-dk.js" /* webpackChunkName: "component---src-pages-case-hormon-og-knoglesygdomme-auh-da-dk-js" */),
  "component---src-pages-case-intensiv-randers-da-dk-js": () => import("./../../../src/pages/case-intensiv-randers.da-dk.js" /* webpackChunkName: "component---src-pages-case-intensiv-randers-da-dk-js" */),
  "component---src-pages-case-mere-tid-randers-da-dk-js": () => import("./../../../src/pages/case-mere-tid-randers.da-dk.js" /* webpackChunkName: "component---src-pages-case-mere-tid-randers-da-dk-js" */),
  "component---src-pages-case-rm-it-da-dk-js": () => import("./../../../src/pages/case-rm-it.da-dk.js" /* webpackChunkName: "component---src-pages-case-rm-it-da-dk-js" */),
  "component---src-pages-cases-da-dk-js": () => import("./../../../src/pages/cases.da-dk.js" /* webpackChunkName: "component---src-pages-cases-da-dk-js" */),
  "component---src-pages-get-started-da-dk-js": () => import("./../../../src/pages/get-started.da-dk.js" /* webpackChunkName: "component---src-pages-get-started-da-dk-js" */),
  "component---src-pages-index-da-dk-js": () => import("./../../../src/pages/index.da-dk.js" /* webpackChunkName: "component---src-pages-index-da-dk-js" */),
  "component---src-pages-index-en-us-js": () => import("./../../../src/pages/index.en-us.js" /* webpackChunkName: "component---src-pages-index-en-us-js" */),
  "component---src-pages-privacypolicy-da-dk-js": () => import("./../../../src/pages/privacypolicy.da-dk.js" /* webpackChunkName: "component---src-pages-privacypolicy-da-dk-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

